<template>
  <c-box id="kuisioner">
    <Header />
    <c-box paddingTop="30px" paddingBottom="80px" :px="['20px', '0']">
      <c-box w="100%" maxWidth="1200px" mx="auto">
        <Breadcrumb
          :items="[
            {
              label: 'Beranda',
              href: $router.resolve({ name: 'client.index' }).href,
            },
            {
              label: 'Kuisioner',
              href: $router.resolve({ name: 'client.kuisioner' }).href,
              isCurrent: true,
            },
          ]"
        />
        <hr v-chakra mt="30px" />
        <Steps :current="1" :total="7" v-chakra mt="30px" />

        <c-box w="100%" maxWidth="590px" mx="auto" marginTop="50px">
          <c-box textAlign="center">
            <c-text
              color="brand.900"
              fontFamily="roboto"
              textTransform="uppercase"
              fontWeight="700"
              fontSize="16px"
              marginBottom="10px"
            >
              Kuisioner (1/7)
            </c-text>
            <c-heading
              fontWeight="600"
              fontSize="36px"
              lineHeight="54px"
              color="black.900"
            >
              Identitas &amp; Data Umum
            </c-heading>
          </c-box>
          <c-box marginTop="80px">
            <form @submit.prevent="submit">
              <c-form-control
                :is-disabled="!isEditable"
                mb="30px"
                is-required
                :is-invalid="$v.firstName.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Nama Depan
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    placeholder="Masukkan nama depan"
                    height="62px"
                    v-model="firstName"
                    readonly
                    disabled
                  />
                </c-input-group>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                mb="30px"
                is-required
                :is-invalid="$v.lastName.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Nama Belakang
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    placeholder="Masukkan nama belakang"
                    height="62px"
                    type="text"
                    v-model="lastName"
                    readonly
                    disabled
                  />
                </c-input-group>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                mb="30px"
                is-required
                :is-invalid="$v.gender.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Jenis Kelamin
                </c-form-label>

                <c-select
                  placeholder="Jenis Kelamin"
                  height="62px"
                  v-model="gender"
                  readonly
                  disabled
                >
                  <option value="male">Laki-laki</option>
                  <option value="female">Perempuan</option>
                </c-select>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                mb="30px"
                is-required
                :is-invalid="$v.age.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Usia
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    placeholder="Masukkan usia"
                    height="62px"
                    type="number"
                    :value="age"
                    readonly
                    disabled
                  />
                </c-input-group>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                mb="30px"
                is-required
                :is-invalid="$v.age.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Tanggal Lahir
                </c-form-label>
                <c-input-group is-disabled size="md">
                  <c-input
                    placeholder="Masukkan usia"
                    height="62px"
                    type="date"
                    v-model="birthDate"
                    readonly
                    disabled
                  />
                </c-input-group>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                mb="30px"
                is-required
                :is-invalid="$v.address.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Alamat
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    id="address"
                    placeholder="Masukkan alamat"
                    height="62px"
                    type="text"
                    v-model="address"
                  />
                </c-input-group>
              </c-form-control>

              <c-form-control
                :is-disabled="!isEditable"
                mb="30px"
                is-required
                :is-invalid="$v.country.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Negara
                </c-form-label>

                <c-select
                  placeholder="Pilih Negara"
                  v-model="country"
                  height="62px"
                >
                  <option
                    v-for="country in countries"
                    :key="country.value"
                    :value="country.name"
                  >
                    {{ country.label }}
                  </option>
                </c-select>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                mb="30px"
                is-required
                :is-invalid="$v.province.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Provinsi
                </c-form-label>

                <c-box class="x-select-container">
                  <select
                    placeholder="Pilih Provinsi"
                    v-model="province"
                    class="x-select"
                  >
                    <option
                      v-for="state in states"
                      :key="state.value"
                      :value="state.name"
                    >
                      {{ state.label }}
                    </option>
                  </select>
                  <c-box w="30px">
                    <c-icon name="chevron-down" size="20px" />
                  </c-box>
                </c-box>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.city.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Kota
                </c-form-label>

                <div class="x-select-container">
                  <select v-model="city" class="x-select">
                    <option value="">Pilih Kota</option>
                    <option
                      v-for="item in cities"
                      :key="item.value"
                      :value="item.name"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                  <c-box w="30px">
                    <c-icon name="chevron-down" size="20px" />
                  </c-box>
                </div>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.zipCode.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Kode Pos
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    id="posCode"
                    placeholder="Masukkan Kode Pos"
                    height="62px"
                    type="text"
                    v-model="zipCode"
                  />
                </c-input-group>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.phone.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Nomor Telepon
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    id="noTlpn"
                    placeholder="Masukkan nomor telepon"
                    height="62px"
                    type="text"
                    v-model="phone"
                  />
                </c-input-group>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.mainTarget.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Apa tujuan utama kamu?
                </c-form-label>

                <c-select
                  placeholder="Pilih tujuan utama kamu"
                  v-model="mainTarget"
                  height="62px"
                >
                  <option value="Mencapai hidup sehat jangka panjang">
                    Mencapai hidup sehat jangka panjang
                  </option>
                  <option value="Mencapai BodyGoals">Mencapai BodyGoals</option>
                </c-select>
                <c-form-helper-text> Apa tujuan kamu</c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.expectedDiet.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Tipikal diet yang kamu harapkan
                </c-form-label>

                <c-select
                  placeholder="Tipikal diet yang kamu harapkan"
                  height="62px"
                  v-model="expectedDiet"
                >
                  <option value="Dimulai dari perubahan bertahap dulu">
                    Dimulai dari perubahan bertahap dulu
                  </option>
                  <option value="Diet ketat">Diet ketat</option>
                </c-select>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.hasScales.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Apakah kamu memiliki timbangan berat badan di rumah?
                </c-form-label>

                <c-select
                  placeholder="Apakah kamu memiliki timbangan berat badan di rumah?"
                  height="62px"
                  v-model="hasScales"
                >
                  <option value="yes">Ya</option>
                  <option value="no">Tidak</option>
                </c-select>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.currentCondition.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Mana yang paling mendeskripsikan kondisi Klien saat ini?
                </c-form-label>

                <c-select
                  placeholder="Mana yang paling mendeskripsikan kondisi Klien saat ini?"
                  height="62px"
                  v-model="currentCondition"
                >
                  <option
                    value="Tidak tahu kondisi gizi dan kesehatannya, belum tertarik untuk tahu karena merasa masih nyaman dengan kondisi saat ini"
                  >
                    Tidak tahu kondisi gizi dan kesehatannya, belum tertarik
                    untuk tahu karena merasa masih nyaman dengan kondisi saat
                    ini
                  </option>
                  <option
                    value="Tidak tahu kondisi gizi dan kesehatannya, tertarik untuk tahu karena ingin berubah"
                  >
                    Tidak tahu kondisi gizi dan kesehatannya, tertarik untuk
                    tahu karena ingin berubah
                  </option>
                  <option
                    value="Tahu kondisi gizi dan kesehatannya, belum tertarik untuk berubah"
                  >
                    Tahu kondisi gizi dan kesehatannya, belum tertarik untuk
                    berubah
                  </option>
                  <option
                    value="Tahu kondisi gizi dan kesehatannya, sudah tertarik untuk melakukan perubahan karena paham manfaat jangka panjangnya"
                  >
                    Tahu kondisi gizi dan kesehatannya, sudah tertarik untuk
                    melakukan perubahan karena paham manfaat jangka panjangnya
                  </option>
                  <option
                    value="Sudah pernah melakukan perubahan diet dan gaya hidup, tapi tidak bertahan lama"
                  >
                    Sudah pernah melakukan perubahan diet dan gaya hidup, tapi
                    tidak bertahan lama
                  </option>
                  <option
                    value="Sudah berhasil melakukan perubahan diet dan gaya hidup dan ingin melakukannya lagi"
                  >
                    Sudah berhasil melakukan perubahan diet dan gaya hidup dan
                    ingin melakukannya lagi
                  </option>
                </c-select>
              </c-form-control>

              <DinamisForm v-model="questions" />

              <c-flex
                justifyContent="space-between"
                alignItems="center"
                marginTop="30px"
              >
                <c-button
                  left-icon="chevron-left"
                  backgroundColor="brand.900"
                  color="white"
                  borderRadius="100px"
                  variant="solid"
                  px="30px"
                  disabled
                >
                  Kembali
                </c-button>
                <c-button
                  right-icon="chevron-right"
                  backgroundColor="brand.900"
                  color="white"
                  borderRadius="100px"
                  variant="solid"
                  px="30px"
                  type="submit"
                >
                  Selanjutnya
                </c-button>
              </c-flex>
            </form>
          </c-box>
        </c-box>
      </c-box>
    </c-box>
    <Footer />
  </c-box>
</template>

<script>
import Header from "@/components/header/index.vue"
import Footer from "@/components/Footer.vue"
import programIdMixins from "@/views/client/kuisioner/programId-mixins"
import { mapGetters, mapState } from "vuex"
import { required, numeric } from "vuelidate/lib/validators"
import Steps from "@/components/steps"
import Breadcrumb from "@/components/breadcrumb"
import DinamisForm from "@/views/client/kuisioner/dinamis-form"
import VSelect from "@/components/VSelect"

export default {
  name: "kuisioner1",
  components: {
    DinamisForm,
    Breadcrumb,
    Steps,
    Header,
    Footer,
    VSelect,
  },
  mixins: [programIdMixins],
  data() {
    return {
      firstName: null,
      middleName: null,
      lastName: null,
      gender: null,
      birthDate: null,
      address: null,
      city: null,
      province: null,
      country: null,
      zipCode: null,
      phone: null,
      mainTarget: null,
      expectedDiet: null,
      hasScales: null,
      currentCondition: null,
      // ----
      countries: [],
      states: [],
      cities: [],
      questions: [],
    }
  },
  validations: {
    firstName: { required },
    lastName: { required },
    gender: { required },
    age: { required, numeric },
    birthDate: { required },
    address: { required },
    city: { required },
    province: { required },
    country: { required },
    zipCode: { required },
    phone: { required },
    mainTarget: { required },
    expectedDiet: { required },
    hasScales: { required },
    currentCondition: { required },
  },
  computed: {
    ...mapState({
      profile: (s) => s.profile.dataProfile,
      kuisData: (s) => s.kuisioner.generalData,
    }),
    ...mapGetters({
      axios: "axios",
    }),
    isEditable() {
      return this.$route.params.isEditable ?? true
    },
    selectedCountry() {
      return this.countries.find((it) => it.value === this.country)
    },
    selectedState() {
      return this.states.find((it) => it.value === this.province)
    },
    selectedCity() {
      return this.cities.find((it) => it.value === this.city)
    },
    value() {
      return {
        firstName: this.firstName,
        middleName: this.middleName,
        lastName: this.lastName,
        gender: this.gender,
        age: this.age,
        birthDate: this.birthDate,
        address: this.address,
        city: this.city,
        province: this.province,
        country: this.country,
        zipCode: this.zipCode,
        phone: this.phone,
        mainTarget: this.mainTarget,
        expectedDiet: this.expectedDiet,
        hasScales: this.hasScales,
        currentCondition: this.currentCondition,
      }
    },
    age() {
      return new Date().getFullYear() - new Date(this.birthDate).getFullYear()
    },
    answers() {
      return this.questions.map((it) => ({
        questionId: it.id,
        answer: it.answer,
        question: it.question,
      }))
    },
  },
  watch: {
    profile: {
      immediate: true,
      handler(profile) {
        if (profile == null) return

        this.firstName ??= profile.firstName
        this.middleName ??= profile.middleName
        this.lastName ??= profile.lastName
        this.birthDate ??= profile.birthDate
        this.gender ??= profile.gender
        this.address ??= profile.address
        this.phone ??= profile.phone
        this.country ??= profile.country
        this.province ??= profile.province
        this.city ??= profile.city
        this.zipCode ??= profile.zipCode
      },
    },
    kuisData: {
      immediate: true,
      handler(kuisData) {
        if (kuisData == null) return

        this.firstName = kuisData.firstName
        this.middleName = kuisData.middleName
        this.lastName = kuisData.lastName
        this.birthDate = kuisData.birthDate
        this.gender = kuisData.gender
        this.address = kuisData.address
        this.phone = kuisData.phone
        this.country = kuisData.country
        this.province = kuisData.province
        this.city = kuisData.city
        this.zipCode = kuisData.zipCode
        this.hasScales = kuisData.hasScales
        this.mainTarget = kuisData.mainTarget
        this.expectedDiet = kuisData.expectedDiet
        this.currentCondition = kuisData.currentCondition
      },
    },
    programId: {
      immediate: true,
      async handler(programId) {
        if (programId == null) return

        await this.$store.dispatch(
          "kuisioner/getV2ClientForCategory",
          "general_data"
        )
        // await this.$store.dispatch("kuisioner/getV2ClientDraft", programId)
        this.questions =
          this.$store.getters["kuisioner/questionFor"]("general_data")
      },
    },
    selectedCountry: {
      immediate: false,
      async handler(country) {
        console.log("selected country", country)
        await this.getStates(country.id)
      },
    },
    selectedState: {
      immediate: false,
      async handler(state) {
        console.log("selected state", state)
        await this.getCities(state.id)
      },
    },
    async selectedCity(city) {
      console.log("selected city", city)
    },
  },
  async mounted() {
    await this.getCountries()
    await this.$store.dispatch("profile/getProfileClient")
    // await Promise.all([this.getCountries(), this.getStates(), this.getCities()])
  },
  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      await this.$store.dispatch("kuisioner/setGeneralData", {
        programId: this.programId,
        answers: this.answers,
        value: this.value,
      })
      await this.$router.push({
        name: "client.kuisioner2",
        params: this.$route.params,
      })
    },
    async getCities(stateId) {
      if (stateId == null) return

      let { data } = await this.axios
        .get(`/v1/general/cities?stateId=${stateId}`)
        .then((it) => it.data)
      this.cities = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        }
      })
    },
    async getStates(countryId) {
      if (countryId == null) return

      let { data } = await this.axios
        .get(`/v1/general/states?countryId=${countryId}`)
        .then((it) => it.data)
      this.states = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        }
      })
    },
    async getCountries() {
      let { data } = await this.axios
        .get("/v1/general/countries")
        .then((it) => it.data)
      this.countries = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        }
      })
    },
  },
}
</script>

<style></style>
